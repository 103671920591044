import { createContext, useContext, useReducer, useState } from "react";
import fetchUserSetting from "src/api/graphQLClient";
import { useInterval } from "src/components/Hooks/useInterval";
import { ChildrenType } from "src/models/view/Children";
import { Notification, NotificationContextValueType, NotificationType } from "src/models/view/NotificationContextTypes";

export const NotificationContext = createContext<NotificationContextValueType>(
    {} as NotificationContextValueType
);

export const NotificationProvider = ({ children }: ChildrenType) => {
    const [userSettingError, setUserSettingError] = useState(false);
    const [state, dispatch] = useReducer(
        (state: NotificationContextValueType, action: any) => ({
            ...state,
            ...action
        }), { messages: [] as Notification[] } as NotificationContextValueType);


    const addNotification = (notification: Notification, type: NotificationType) => {
        if (type === NotificationType.Both || type === NotificationType.Notification) {
            dispatch({ currentNotification: notification });
        }
        if (type === NotificationType.Both || type === NotificationType.Message) {
            let messages = [...state.messages, notification];
            messages.sort((a, b) => b.createTime.valueOf() - a.createTime.valueOf());
            dispatch({ messages: messages });
        }
    }
    const value: NotificationContextValueType = {

        currentNotification: state.currentNotification,
        messages: state.messages,
        addNotification: addNotification,
    }

    useInterval(() => {
        if (!userSettingError) {
            fetchUserSetting('UnreadUserPoppers').then((response) => {
                if (response
                    && response.data
                    && response.data.getUserSetting
                    && response.data.getUserSetting.value) {
                    const parsedPoppers = JSON.parse(response.data.getUserSetting.value);
                    if (parsedPoppers.unread.length > 0) {
                        dispatch({ messages: [...state.messages, parsedPoppers.unread] });
                    }
                }
            }).catch((e) => {
                setUserSettingError(true);
            })
        }
        if (state.messages && state.messages.length > 0) {
            let messages = state.messages as Notification[];
            let filteredMessages = messages.filter(m => {
                let now = new Date();
                let expInSecs: number = m?.expiresInSeconds ?? 60;
                var deadline = new Date(now.getTime() - expInSecs * 1000);
                return m.createTime > deadline;
            });
            dispatch({ messages: [...filteredMessages] })
        }
        if (state.currentNotification) {
            let now = new Date();
            let expInSecs: number = state.currentNotification?.expiresInSeconds ?? 60;
            var deadline = new Date(now.getTime() - expInSecs * 1000);
            if (state.currentNotification.createTime < deadline) {
                dispatch({ currentNotification: null })
            }
        }
    }, 10000);
    return (
        <NotificationContext.Provider value={value} >
            {children}
        </NotificationContext.Provider>
    );

};
const useNotifications = () => {
    const context = useContext(NotificationContext)
    if (context === undefined) {
        throw new Error("useNotifications must be used within NotificationContext");
    }
    return context;
}
export default useNotifications;