import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
 import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './api/authConfig';
import { MsalProvider } from '@azure/msal-react';
import './i18n'

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS 
      && event.payload
      && "account" in event.payload // check if the type has "account" property
      && event.payload.account) { // check if account property is set
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
  
  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <HelmetProvider>
          <SidebarProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SidebarProvider>
        </HelmetProvider>
    </MsalProvider>,
    document.getElementById('root')
  );
});
serviceWorker.unregister();
