import { useRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { darken, styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import useUser from 'src/contexts/UserContext';

const UserBoxButton = styled(Button)(
  ({ theme }) => ({
    color: theme.colors.secondary.dark,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      background: theme.colors.secondary.lighter
    }
  })
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.secondary.dark};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        color: ${theme.colors.secondary.dark};
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.dark};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.secondary.dark};
`
);

function HeaderUserbox() {
  const userContext = useUser();
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={userContext.user.name} src={userContext.user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{userContext.user.name}{userContext.user.userName
              && <>
                {!userContext.user.isExternal && <> [{userContext.user.userName}]</>}
                {userContext.user.isExternal && <> [EXT]</>}
              </>}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {userContext.user.email}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon color="secondary" sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        disableScrollLock={true}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={userContext.user.name} src={userContext.user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{userContext.user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {userContext.user.email}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />


        <List sx={{ p: 1 }} component="nav" onClick={handleClose}>
          <ListItem className='userbox-listitem' button to="/management/profile/details" component={RouterLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('profile.my_profile')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth
            variant='contained'
            component={RouterLink}
            to="/logout">
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('profile.sign_out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
