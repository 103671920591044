export enum NotificationType {
    Notification,
    Message,
    Both
}

export enum NotificationCategory {
    Success = "success",
    Info = "info",
    Warning = "warning",
    Error = "error"
}

export type Notification = {
   category:NotificationCategory;
   message:string;
   subtitle?:string;
   createTime:Date;
   expiresInSeconds?:number;
}

export type NotificationContextValueType = {
    messages : Notification[];
    currentNotification : Notification;
    addNotification : (notification:Notification, type : NotificationType) => void;
}