import { createContext, useContext, useReducer, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { loginRequest } from 'src/api/authConfig';
import { callMsGraph, getUserPhoto } from 'src/api/msGraphApiCall';
import { ChildrenType } from "src/models/view/Children";
import { UserStateType } from "src/models/view/UserStateType";
import { UserContextValueType } from "src/models/view/UserContextValueType";
import i18n from "i18next";
import { client } from "src/api/client";
import { fetchUserTimeZone } from "src/api/graphQLClient";
import { TimeZoneType } from "src/models/view/TimeZoneType";
import LanguageType from "src/models/view/LanguageType";
import { Language } from "src/models/api/Language";

export const UserContext = createContext<UserContextValueType>(
  {} as UserContextValueType
);

export const UserProvider = ({ children }: ChildrenType) => {
  const [state, dispatch] = useReducer(
    (state: UserStateType, action: any) => ({
      ...state,
      ...action
    }), { language: 'en', loaded: false } as UserStateType);
  const { instance, inProgress } = useMsal();
  const [availableLanguages, setAvailableLanguages] = useState<Array<Language>>([]);
  const requestAccount = instance.getActiveAccount() ?? '';
  const [identity, setIdentity] = useState();
  const setUserAvatar = (response: Blob | void) => {
    if (response instanceof Blob) {
      const url = URL.createObjectURL(response);
      dispatch({ avatar: url });
    }
  }

  const timeZoneRegex = /\(UTC\+(\d+)\:(\d+)\)\s?(.*)/gi;

  const changeSettings = (language: string, culture: string) => {
    if (availableLanguages
      && availableLanguages.length) {
      const requestedLanguage = availableLanguages.filter((beLanguage) => beLanguage.LangCode === language);
      if (requestedLanguage 
        && requestedLanguage[0]
        && requestedLanguage[0].Id) {
        return client.putOdata('DataObjects/User(' + state.userId + ')', {
          Culture: culture,
          LangId: requestedLanguage[0].Id
        }).then((updatedUserResponse) => {
          if (updatedUserResponse.data 
            && updatedUserResponse.data.LangId
            && updatedUserResponse.data.LangId === requestedLanguage[0].Id
            && updatedUserResponse.data.Culture
            && updatedUserResponse.data.Culture === culture) {
              i18n.changeLanguage(language);
              dispatch({ language: language, selectedRegionalSetting: culture });
          }
        })
      }
    }

    // not to anger Typescript
    return Promise.resolve()
    .catch(() => {
      console.log('user language catch');
    })
    .then(() => {
      console.log('user language then');
    });
  }


  const getFromGrapData = (response: any, key: string, defaultValue?: string) => {
    if (response !== null 
      && key in response
      && response[key]
      && response[key] !== null) {
      return response[key];
    }
    return defaultValue;
  }

  const loadUserSettings = () => {
    getUserPhoto().then((response) => {
      if (response) {
        setUserAvatar(response as Blob)
      }
    }).catch((e) => {
      // no user photo
      console.log('no msGraph photo set', e);
    });

    // if (!timeZoneError) {
    //   fetchUserTimeZone()
    //     .then((response) => {
    //       setTimeZoneError(false);
    //       if (response.data
    //         && response.data.userSettings
    //         && 'timeZone' in response.data.userSettings) {
    //         const userTimeZone = response.data.userSettings.timeZone as TimeZoneType;
    //         dispatch({ timezone: userTimeZone })
    //       }
    //     }).catch((e) => {
    //       setTimeZoneError(true);
    //     });
    // }
    client.get(process.env.REACT_APP_BASE_API + 'webservice/custom/uixservice.asmx/GetActualUserInfo')
      .then(response => {
        if (response
          && response.data
          && response.data.d) {
          let cultureArr: { key: string, value: string }[] = [];
          response.data.d.AllowedRegionalSettings.forEach((element: { Key: string, Value: string }) => {
            let z = element;
            cultureArr.push({
              key: element.Key,
              value: element.Value
            });
          });

          if (state.language !== response.data.d.SelectedLanguage) {
            i18n.changeLanguage(response.data.d.SelectedLanguage);
          }
          dispatch({
            loaded: true,
            allowedRegionalSettings: cultureArr,
            userUId: response.data.d.UserUId,
            identityUId: response.data.d.IdentityUId,
            userInfoError: false,
            userInfoErrorMsg: ''
          });

          // get Omada user object
          client.get(process.env.REACT_APP_BASE_API + 'OData/DataObjects/User?$filter=UId eq ' + response.data.d.UserUId)
            .then(userResponse => {
              if (userResponse
                && userResponse.data
                && userResponse.data.value
                && userResponse.data.value[0]
                && userResponse.data.value[0].UId === response.data.d.UserUId) {
                const userObject = userResponse.data.value[0];

                // store username
                dispatch({
                  userId: userObject.Id,
                  userName: userObject.UserName
                });

                // get the user’s timezone
                client.get(process.env.REACT_APP_BASE_API + 'OData/BuiltIn/TimeZones(' + userObject.TimeZoneId + ')')
                  .then(response => {
                    if (response.data
                      && response.data.Name) {
                      // deserialize the timezone string
                      const timezoneMatches = [...response.data.Name.matchAll(timeZoneRegex)];
                      if (timezoneMatches.length
                        && timezoneMatches[0].length >= 4) {
                        // convert hours to minutes and add minutes
                        const offset = parseInt(timezoneMatches[0][1]) * 60 + parseInt(timezoneMatches[0][2]);

                        const userTimezone = {
                          baseUtcOffsetInMinutes: offset,
                          standardName: timezoneMatches[0][3]
                        } as TimeZoneType;
                        dispatch({
                          timezone: userTimezone,
                          timeZoneError: false
                        });
                      }
                    }
                  }).catch((e) => {
                    dispatch({
                      timeZoneError: true
                    })
                  })

                // set user regional settings in context
                if (userObject.Culture) {
                  dispatch({
                    selectedRegionalSetting: userObject.Culture
                  })
                }

                const userLanguage = userObject.LangId;
                if (userLanguage) {
                  // load languages from backend
                  if (!availableLanguages || availableLanguages.length === 0) {
                    client.getOdata('/BuiltIn/Languages')
                      .then(languageResponse => {
                        if (languageResponse.data
                          && languageResponse.data.value
                          && languageResponse.data.value.length) {
                          // check the language info
                          setAvailableLanguages(languageResponse.data.value);
                          setUserLanguage(userLanguage, languageResponse.data.value);
                        }
                      }).catch((e) => {
                        // couldn’t load language info
                      })
                  } else { // available languages are loaded, map Id to key
                    setUserLanguage(userLanguage, availableLanguages)
                  }
                }

                // get user signature
                if (identity) {
                  getUserSignature(identity);
                } else {
                  client.getOdata('DataObjects/Identity(' + userObject.IDENTITYREF.Id + ')')
                    .then((identityResponse) => {
                      if (identityResponse.data
                        && identityResponse.data.UId) {
                        setIdentity(identityResponse.data);
                        dispatch({ identity: identityResponse.data })
                        getUserSignature(identityResponse.data);
                      }
                    })
                }

              }
            })
        }
      })
      .catch((e) => {
        dispatch({
          userInfoError: true,
          userInfoErrorMsg: e
        })
      })

    const setUserLanguage = (langId: number, languageInfo: Array<LanguageType>) => {
      const selectedLanguage = languageInfo.filter((lang) => lang.Id === langId);
      if (selectedLanguage
        && selectedLanguage[0]) {

        dispatch({
          language: selectedLanguage[0].LangCode
        });
      }
    }

    const getUserSignature = (identity: object) => {
      if ('VE_SIGNATURE_SR' in identity
        && typeof identity.VE_SIGNATURE_SR === 'object'
        && identity.VE_SIGNATURE_SR !== null
        && 'Id' in identity.VE_SIGNATURE_SR) {
        client.getOdata('DataObjects/Signatures(' + identity.VE_SIGNATURE_SR.Id + ')').then((signatureResponse) => {
          if (signatureResponse
            && signatureResponse.data
            && signatureResponse.data.VE_SIGNATURE_SECURED_VT) {
            dispatch({
              signature: signatureResponse.data.VE_SIGNATURE_SECURED_VT
            });
          }
        })
      }
    }

    callMsGraph().then((response) => {
      if (response.error
        && response.error.code) {
        let errorMsg = response.error.code;
        if (response.error.message) {
          errorMsg += ' ' + response.error.message
        }

        dispatch({
          msGraphError: true,
          msGraphErrorMessage: errorMsg
        });
      } else {
        let displayName = '';
        let givenName = getFromGrapData(response, 'givenName', '');
        let surname = getFromGrapData(response, 'surname', '');
        let upn = getFromGrapData(response, 'userPrincipalName', '');
        let isExternal = upn.indexOf('#EXT#') >= 0;

        if ((givenName === '' 
          || surname === '')
          && response.displayName
        ) {
          displayName = response.displayName
        } else {
          displayName = givenName + ' ' + surname;
        }

        let mail = getFromGrapData(response, 'mail', '');
        dispatch({
          name: displayName,
          email: mail,
          upn: upn,
          isExternal: isExternal
        });
      }
    }).catch((e): void => {
      if (e instanceof InteractionRequiredAuthError
        && requestAccount
        && loginRequest
        && "scopes" in loginRequest) {
        let scopes = new Array<string>();
        if (loginRequest.scopes && loginRequest.scopes.length) {
          loginRequest.scopes.map((e) => {
            if (typeof e === 'string') {
              scopes.push(e);
            }
          })
        }
        const request: RedirectRequest = {
          scopes: scopes,
          account: requestAccount
        };
        instance.acquireTokenRedirect(request);
      }
    });
  }

  /**
   * posts a base64 image to the UIXService to set as the user’s signature
   * @param imageUrl
   */
  const setSignature = (imageUrl: string): Promise<any> => {
    const user = state as UserStateType;
    return client.post(
      process.env.REACT_APP_BASE_API + 'WebService/Custom/UIXService.asmx/CreateSignature',
      {
        identityUid: user.identityUId,
        signatureImage: imageUrl,
      },
      {
        contentType: 'application/json; charset=utf-8'
      }
    ).then((result) => {
      if (result.status === 200) {
        if (result.data && result.data.d) {
          // set new signature on success
          dispatch({ signature: result.data.d })
        }
      }
      return result;
      // @TODO: handle other status codes / empty/invalid response
    });
    // @TODO: return error / promise to component to display user error
  }

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      loadUserSettings();
    }
  }, [inProgress]);

  const value: UserContextValueType = {
    user: state as UserStateType,
    changeSettings: changeSettings,
    setSignature: setSignature,
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
};

const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("useUser must be used within UserContext");
  }
  return context;
}
export default useUser;