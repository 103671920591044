import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component: React.FC) => (props: any) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const HelpPage = Loader(
  lazy(() => import('src/content/pages/Help'))
);
const LoggedOut = Loader(
  lazy(() => import('src/content/pages/Status/LoggedOut'))
);

// Dashboards
const Home = Loader(
  lazy(() => import('src/content/dashboards/Home'))
);
// Applications
const Logout = Loader(
  lazy(() => import('src/components/Logout/Logout'))
);
const ResourceAssignments = Loader(
  lazy(() => import('src/content/applications/ResourceAssignments'))
);

const WorkItemsUiWebservice = Loader(
  lazy(() => import('src/content/applications/WorkItems/list/WorkItemsUIWebservice'))
);

const DetailView = Loader(
  lazy(() => import('src/content/applications/DataObjects/Detailspage'))
);

const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSignature = Loader(
  lazy(() => import('src/content/applications/Users/signature'))
);
const WorkItems = Loader(
  lazy(() => import('src/content/pages/WorkItems'))
);
const WorkItemsList = Loader(
  lazy(() => import('src/content/applications/WorkItems/list/WorkItemsList'))
);
 
const TestPage = Loader(
  lazy(() => import('src/content/pages/Test'))
);
 

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards/home" replace />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'logout',
        element: <Logout />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: 'loggedout',
            element: <LoggedOut />,
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'details',
    element: <SidebarLayout />,
    children: [
      {
        path: ':id',
        element: <DetailView />
      },
      {
        path: '',
        element: <Navigate to="/dashboards/home" replace />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path: 'home',
        element: <Home />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: 'workitems',
        element: <WorkItems />
      },
      {
        path: 'workitemslist',
        element: <WorkItemsList />
      },
      {
        path: 'workitems/:appId',
        element: <WorkItems />
      },
      {
        path: 'assignments',
        element: <ResourceAssignments />
      },
      {
        path: 'workitemsuiws',
        element: <WorkItemsUiWebservice />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },

          {
            path: 'signature',
            element: <UserSignature />
          },
          {
            path: 'test',
            element: <TestPage />
          }
        ]
      },
      {
        path: 'help',
        element: <HelpPage />,
      }
    ]
  }

];

export default routes;
