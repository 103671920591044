import DOExpressionOptions from "src/models/view/DataObjectExpressions";

/**
        Builds an xml representation of data object expressions which can be used for calling the GetPagingData ws.
        options:
        .expressions: array of expression objects:
        .path: reference path from the objects in the view to the objects the expression is for
        .property: property/fixed field to filter on
        .operator: operator [Equals, LessThan, GtThan, LessThanEq, GtThanEq, NotEquals, Like, In, NotIn]
        .value: filter value. Can be either a: string, boolean, DateTime or array of string.
            An array of string is valid if the property is a Set- or Reference-property. It can then specify int or guid ids.
        Returns a string with an xml representation of the expressions.
    */
function buildDataObjectExpressionsXml(options: DOExpressionOptions) {
    if (!options || !options.expressions)
        throw new Error('A required parameter is missing!');
    var expressionsXml = '';
    for (var ii = 0; ii < options.expressions.length; ii++) {
        var expr = options.expressions[ii];
        var pathAttrib = expr.path ? ' path="' + expr.path + '"' : '';
        var exprValue = Array.isArray(expr.value) ? expr.value : [expr.value];
        for (var jj = 0; jj < exprValue.length; jj++) {
            if (typeof exprValue[jj] === 'boolean')
                exprValue[jj] = exprValue[jj] ? 'True' : 'False';
            else if (Object.prototype.toString.call(exprValue[jj]) === '[object Date]')
                exprValue[jj] = exprValue[jj].toISOString();
        }
        expressionsXml += '<expression' + pathAttrib + ' property="' + expr.property + '" operator="' + expr.operator + '" value="' + exprValue.join(',') + '"/>';
    }
    if (expressionsXml != '')
        expressionsXml = '<expressions>' + expressionsXml + '</expressions>';
    return expressionsXml;
}

export default buildDataObjectExpressionsXml;