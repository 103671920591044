import  { useEffect, useRef } from 'react';
interface RefObject {
    action: () => void
}
export function useInterval(callback:()=>void, delay:number) {
  const savedCallback = useRef<RefObject>({action:()=>{}});

  // Remember the latest callback.
  useEffect(() => {
    (savedCallback.current as RefObject).action = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.action();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}