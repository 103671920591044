import { useEffect } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'src/api/authConfig';

const pca = new PublicClientApplication(msalConfig);

const AuthHandler = () => {
    useEffect(() => {
        const authenticate = async () => {
            pca.loginRedirect();
        };

        authenticate();
    }, []);

    return null; // This component doesn't render anything
};

export default AuthHandler;