import    { useEffect } from "react";
import { useMsal, } from "@azure/msal-react";
import {   InteractionStatus } from "@azure/msal-browser";
import { t } from "i18next";

export default function Logout() {
    const { instance,inProgress } = useMsal();
    useEffect(() => {
          instance.handleRedirectPromise()
          .then(res=>{
            if (inProgress === InteractionStatus.None) {
              instance.logoutRedirect({account: instance.getActiveAccount()});
            }
          }).catch(err => {
            console.error(err);
          });
    }, [ inProgress ]);

    return (
        <div>{t('status.loggingOut')}</div>
    )
}