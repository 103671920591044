import { Route, Routes, useRoutes } from 'react-router-dom';
import router from 'src/router';
import { LicenseInfo } from '@mui/x-license-pro';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import AuthHandler from './components/Login';
import { UserProvider } from './contexts/UserContext'
import { WorkItemProvider } from './contexts/WorkItemContext';
import BaseLayout from './layouts/BaseLayout';
import Favicon from './components/Logos/Favicon';
import { HelpProvider } from './contexts/HelpContext';
import LoggedOut from './content/pages/Status/LoggedOut';
import { Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Logout from './components/Logout/Logout';
import { NotificationProvider } from './contexts/NotificationContext';
import NotificationList from './components/Notifications/NotificationList';

function App() {
  const licenseKey = process.env.REACT_APP_LICENSE_KEY;
  if (licenseKey) {
    LicenseInfo.setLicenseKey(licenseKey);
  }

  const content = useRoutes(router);
  return (
    <Suspense fallback={<SuspenseLoader />} >
      <NotificationProvider>
        <ThemeProvider>

          <CssBaseline />
          <Favicon />

          <AuthenticatedTemplate>


            <UserProvider>
              <HelpProvider>
                <WorkItemProvider>
                  {content}
                </WorkItemProvider>
              </HelpProvider>
            </UserProvider>

          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/" element={<BaseLayout />}>
                <Route index element={<AuthHandler />} />
                <Route path="logout" element={<Logout />} />
                <Route path="status/loggedout" element={

                  <LoggedOut />
                }></Route>
                <Route path="*" element={<AuthHandler />} />
              </Route>
            </Routes>
          </UnauthenticatedTemplate>

        </ThemeProvider>
      </NotificationProvider>
    </Suspense>
  );
}
export default App;
