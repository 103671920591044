 
import {
    Box,
    Card,
    Typography,
    Container,
    Button,
  } from '@mui/material';
  import { Helmet } from 'react-helmet-async';
  import { useTranslation } from "react-i18next";
  import { styled } from '@mui/material/styles';
  
  const MainContent = styled(Box)(
    ({ theme }) => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
  );
   
  function LoggedOut() {
    const { t } = useTranslation();
    return (
      <>
        <Helmet>
          <title>{t("status.loggedOut.title")}</title>
        </Helmet>
        <MainContent>
          <Container maxWidth="md">
            <Box textAlign="center">
              <img alt="Logged out" height={180} src="/static/images/logout.svg" />
              <Typography variant="h2" sx={{ my: 2 }}>
                {t("status.loggedOut.message")}
              </Typography>
            
            </Box>
            <Container maxWidth="sm">
              <Card sx={{ textAlign: 'center', mt: 3, p: 4 }}>
                 <Button href="/" variant="outlined">
                 {t("status.loggedOut.goToHomepage")}
                </Button>
              </Card>
            </Container>
          </Container>
        </MainContent>
      </>
    );
  }
  
  export default LoggedOut;
  