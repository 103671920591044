import {
  Alert,
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';
import useNotifications from 'src/contexts/NotificationContext';
import { useTranslation } from "react-i18next";

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const {messages} = useNotifications();
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('notifications.title')}>
        <IconButton  color="secondary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={messages?.length??0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        disableScrollLock={true}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
        <Typography variant="h5">{t('notifications.title')}</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          { messages?.map( (message,index)=>(
               <ListItem key={index}
                sx={{ p: 0.5,  display: { xs: 'block', sm: 'flex' } }}
              >
           <Alert severity={message.category}>
            <Typography variant='h6'>
              {message.message}
            </Typography>
            <Typography variant='subtitle2'>
             {message?.subtitle??''}
            </Typography>
            </Alert>
            </ListItem>
          ))        
        }
        {
          !(messages) || messages.length==0 &&(
            <Alert  severity={'info'}>{t('notifications.noNotifications')}</Alert>
          )
        }
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
