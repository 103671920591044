import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { graphConfig, msGraphRequest } from "./authConfig";
import { msalInstance } from "src";

export async function callMsGraph() {

    const accessToken = await acquireMsGraphAccessToken();

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch((error) => {throw error});
}

export async function getUserPhoto() {
    const accessToken = await acquireMsGraphAccessToken();

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint + '/photo/$value', options)
        .then((response) => {
            if (response.status === 200) {
                response.blob();
            } else {
                return null;
            }
        })
        .catch((e) => {
            console.log('msGraph no user photo', e);
        })
}

async function acquireMsGraphAccessToken() {
    await msalInstance.handleRedirectPromise();
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    let token;
    const response = await msalInstance.acquireTokenSilent({
        ...msGraphRequest,
        account: account
    }).then(r=>{
        token = r.accessToken;
    }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(msGraphRequest)
        }
    });
    //return response.accessToken;
    return token;
}