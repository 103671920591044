import { Dictionary } from "src/models/view/ListItem";
import { client } from "./client";

const defaultApiPath = 'api/query/';
const domainApiPath = 'api/domain/2.6';

type GraphQlViewQueryVariables = {
  id: string | number,
  viewIdentifierType?: string,
  page?: number,
  rows?: number,
  filters?: string,
  search?: string,
  sortColumn?: string,
  sortOrder?: string
}

export function query(
  operationName: string,
  query: string,
  variables: Dictionary<string | number>,
  domainQuery: boolean = false) {
  let apiPath = defaultApiPath;
  if (domainQuery) {
    apiPath = domainApiPath;
  }
  return client.post(
    process.env.REACT_APP_BASE_API + apiPath,
    {
      operationName: operationName,
      query: `${query}`,
      variables
    }
  );
}

export function fetchUserSetting(settingName: string) {
  return query(
    'getUserSetting',
    `query getUserSetting($key: String!) {
            getUserSetting(key: $key) {
              key value __typename
            }
          }
        `,
    {
      key: settingName
    }
  );
};

export function fetchUserTimeZone() {
  return query(
    'getUserTimeZone',
    `query getUserTimeZone {
            userSettings {
              timeZone {
                baseUtcOffsetInMinutes
                name
                standardName
                __typename
              }
              __typename
            }
          }`,
    {

    },
    true
  );
}

export function getViewData({
  id,
  viewIdentifierType = 'VIEW',
  page = 1,
  rows = 20,
  filters = "",
  search = "",
  sortColumn = "",
  sortOrder = "ASC"
}: GraphQlViewQueryVariables) {
  return query(
    'getViewData',
    "query getViewData($id: ID!, $viewIdentifierType: ViewIdentifierType, $page: Int!, $rows: Int!, $filters: String!, $search: String!, $sortColumn: String!, $sortOrder: SortOrder!) {      getViewData(        id: $id        viewIdentifierType: $viewIdentifierType        page: $page        rows: $rows        filters: $filters        searchString: $search        sortColumn: $sortColumn        sortOrder: $sortOrder      ) {        currentPage        totalRecords        totalPages        workItemRows {          properties          displayName          workItemId          processId          __typename        }        dataObjectRows {          properties          displayName          id          uid          typeId          typeUId          deleted          isSpecialType          typeVersioning          permUpdate          permDelete          permTerminate          permMove          __typename        }        __typename      }    }",
    {
      id: id,
      viewIdentifierType: viewIdentifierType,
      page: page,
      rows: rows,
      filters: filters,
      search: search,
      sortColumn: sortColumn,
      sortOrder: sortOrder
    }
  );
}

export default fetchUserSetting;