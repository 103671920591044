// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import { loginRequest } from "./authConfig";
import { msalInstance } from "src";
import { BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import buildDataObjectExpressionsXml from "./buildDoExpressionsXml";

export async function client(endpoint, { body, ...customConfig } = {}) {
  const token = await acquireAccessToken();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }

  let data
  try {
    const response = await window.fetch(endpoint, config)

    if (response.headers.get('Content-Type').indexOf('application/json') === 0) {

      data = await response.json();
    }
    else if (response.headers.get('Content-Type').indexOf('application/octet-stream') === 0) {
      data = response.body;
    }
    else {
      data = await response.text();
    }
    if (response.ok) {
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      }
    }
    throw new Error(data?.Message ? data.Message : response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data)
  }
}

export const acquireAccessToken = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  }).catch(error => {
    if (error instanceof InteractionRequiredAuthError
      || error instanceof BrowserAuthError) {
      // fallback to interaction when silent call fails
      msalInstance.acquireTokenRedirect(loginRequest)
    }
  });
  return response.accessToken;
};

client.getOdata = function (objectName, customConfig = {}) {
  return client(process.env.REACT_APP_BASE_API + 'OData/' + objectName, { ...customConfig, method: 'GET' })
}

client.putOdata = function (objectName, body, customConfig = {}) {
  return client(process.env.REACT_APP_BASE_API + 'OData/' + objectName, { ...customConfig, body, method: 'PUT' })
}

client.getWebservice = function (serviceName, customConfig = {}) {
  return client.get(process.env.REACT_APP_BASE_API + 'Webservice/' + serviceName, customConfig);
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body })
}

client.postWebservice = function (serviceName, body, customConfig = {}) {
  return client.post(process.env.REACT_APP_BASE_API + 'Webservice/' + serviceName, body, customConfig);
}

client.uiWsGetDataObjects = function (params) {
  let expressions = [
  ];

  if (params.expressions) {
    expressions = params.expressions;
  } else if (params.dataObjectType) {
    expressions.push(
      {
        property: 'Type',
        operator: 'Equals',
        value: params.dataObjectType
      })
  }

  let options = {
    ids: params.ids ?? null,
    expressions: buildDataObjectExpressionsXml({
      expressions: expressions
    }),
    pageContext: params.pageContext ?? "",
    path: params.path ?? null,
    populateHasCreateRight: params.populateHasCreateRight ?? null,
    properties: params.properties ?? null,
    skipNonExisting: params.skipNonExisting ?? null,
    sorting: params.sorting ?? null,
    viewId: params.viewId ?? null,
  };

  return client.postWebservice('/UIWebService.asmx/GetDataObjects',
    options)
}

client.download = async function (endpoint, progressUpdate = (progress) => { }, customConfig = {}) {
  const response = await client(endpoint, { ...customConfig, method: 'GET' })
  const reader = response.data.getReader();
  // Step 2: get total length
  const contentLength = +response.headers.get('Content-Length');
  // Step 3: read the data
  let receivedLength = 0; // received that many bytes at the moment
  let chunks = []; // array of received binary chunks (comprises the body)
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    chunks.push(value);
    receivedLength += value.length;
    let progress = (receivedLength * 100) / contentLength
    if (progressUpdate && progress) { progressUpdate(progress); }
  }
  return new Blob(chunks);
}

client.getAllViews = async () => {
  let datetime = new Date();
  let timestamp = datetime.getTime();
  return client.postWebservice('JQGridPopulationWebService.asmx/GetPagingData', {
    dataType: 'Views',
    dataTypeArgs: {
      OwnerShipType: 'Both',
    },
    nd: timestamp,
    page: 1,
    rows: 500,
    sidx: 'name',
    sord: 'asc',
    _search: false,
    filters: null,
    searchOper: null,
    searchString: null,
    searchField: null
  })
}
