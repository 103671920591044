import { createContext, useContext, useReducer, useEffect } from "react";
import { ChildrenType } from "src/models/view/Children";
import { WorkItemContextValueType } from "src/models/view/WorkItemContextValueType";
import { client } from "src/api/client";
import useUser from 'src/contexts/UserContext';
import { WorkItemContainer, WorkItemsStateType } from "src/models/view/WorkItemsStateType";
import { WorkItemPreview, DisplayValueType, WorkItemDetails, BaseRegion, OrderdRegion, FieldRegion, AttachmentRegion, FieldTypes } from "src/models/api/WorkItems/WorkItemsModels";
import { useInterval } from 'src/components/Hooks/useInterval'
import useNotifications from "./NotificationContext";
import { NotificationCategory, NotificationType } from "src/models/view/NotificationContextTypes";
import { useTranslation } from "react-i18next";
export const WorkItemContext = createContext<WorkItemContextValueType>(
  {} as WorkItemContextValueType
);

export const WorkItemProvider = ({ children }: ChildrenType) => {
  const [state, dispatch] = useReducer(
    (state: WorkItemsStateType, action: any) => ({
      ...state,
      ...action
    }), {
      workItemContainers: [],
      previewsLoaded: false,
      previewsInProgress: false,
      detailsInProgress: false,
      previewsError: false,
      detailsError: false,
      previewsErrorMessage: '',
      detailsErrorMessage: ''
    } as WorkItemsStateType);
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const userContext = useUser()
  const nl2br = function (str: string) {
    var breakTag = '<br>'; // Adjust comment to avoid issue on phpjs.org display
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  const resolveApprovalPreviewValues = (approval: WorkItemPreview) => {
    approval.ResolvedSubject = '';
    approval.ProcessedContent = '';
    approval.AppId = approval.ActivityUid + "_" + approval.SurveyObjectKey;
    approval.CreationDate = new Date(approval.CreationDateString)
    if (approval.Content.Type === DisplayValueType.Resolver
      && !(undefined === approval.Content.Resolver)) {
      // eslint-disable-next-line
      const contentResolver = Function(approval.Content.Resolver);
      if ('function' === typeof contentResolver) {
        approval.ProcessedContent = contentResolver(approval.Content.Content);
      }
    } else {
      approval.ProcessedContent = nl2br(approval.Content.Content);
    }

    if (approval.Subject.Type === DisplayValueType.Resolver
      && !(undefined === approval.Subject.Resolver)) {
      // eslint-disable-next-line 
      const subjectResolver = Function(approval.Subject.Resolver);
      if ('function' === typeof subjectResolver) {
        approval.ResolvedSubject = subjectResolver(approval.Subject.Content);
      }
    }
    if (!approval.ResolvedSubject) {
      approval.ResolvedSubject = approval.Subject.Content;
    }
    return approval;
  }

  const resolveApprovalDetailsValues = (approval: WorkItemDetails) => {
    if (undefined !== approval.StartUpScript && approval.StartUpScript !== null) {
      // eslint-disable-next-line
      const startUpScript = Function(approval.StartUpScript);
      if ('function' === typeof startUpScript) {
        startUpScript();
      }
    }
    let regions: OrderdRegion[] = [];

    if (approval) {
      if (approval.FieldRegions) {
        approval.FieldRegions.forEach(fr => {
          let fieldRegion = fr;
          fieldRegion.DisplayFields.forEach(df => {
            if (df.FieldType == FieldTypes.Display) {
              let valueType = df.DisplayValue.Type
              if (valueType == DisplayValueType.Resolver) {
                // eslint-disable-next-line @typescript-eslint/no-implied-eval
                const subjectResolver = Function(df.DisplayValue.Resolver);
                if ('function' === typeof subjectResolver) {
                  df.DisplayValue.Content = subjectResolver();
                }
              }
            }
          });
          fieldRegion.Fields = [...fieldRegion.InputFields, ...fieldRegion.BuiltInFields, ...fieldRegion.DisplayFields];
          fieldRegion.Fields.sort((a, b) => a.Order - b.Order);

          regions.push({ Type: "FieldRegion", Order: fr.Order, Region: fr as FieldRegion });
        });
      }
    }
    if (approval.AttachmentRegions) {
      approval.AttachmentRegions.forEach(ar => {
        regions.push({ Type: "AttachmentRegion", Order: ar.Order, Region: ar as AttachmentRegion });
      });
    }
    regions.sort((a, b) => {
      return a.Order - b.Order;
    });
    approval.SortedRegions = regions;
    return approval;
  }

  const loadWorkItemPreviews = (resetCache: boolean) => {
    dispatch({ previewsLoaded: false, previewsInProgress: true });
    client.get(process.env.REACT_APP_BASE_API + 'webservice/custom/uixservice.asmx/GetWorkItemList')
      .then(response => {
        if (response && response.data && response.data.d) {
          let workItemPreviews = response.data.d as WorkItemPreview[]
          let workItemContainers = state.workItemContainers as WorkItemContainer[]
          if (resetCache) {
            workItemContainers = [];
          }
          //gets new workitems not in current state
          const newWIs = workItemPreviews.filter(nwi => !workItemContainers.some(cwi => cwi.activityUid === nwi.ActivityUid && cwi.surveyObjectKey === nwi.SurveyObjectKey));
          //filter workitems that are in state but not in response 

          let deletedWorkItems = workItemContainers.filter(cwi => !workItemPreviews.some(nwi => cwi.activityUid === nwi.ActivityUid && cwi.surveyObjectKey === nwi.SurveyObjectKey));
          deletedWorkItems.forEach(dwi => {

            addNotification({ message: t('notifications.workRemovedOnServer'), subtitle: dwi.workItemPreview.ResolvedSubject, category: NotificationCategory.Info, createTime: new Date() }, NotificationType.Message);
          })

          workItemContainers = workItemContainers.filter(cwi => workItemPreviews.some(nwi => cwi.activityUid === nwi.ActivityUid && cwi.surveyObjectKey === nwi.SurveyObjectKey));
          newWIs.forEach(nwi => {
            let workItemContainer = {} as WorkItemContainer;
            workItemContainer.workItemPreview = resolveApprovalPreviewValues(nwi);
            workItemContainer.appId = workItemContainer.workItemPreview.AppId;
            workItemContainer.activityUid = workItemContainer.workItemPreview.ActivityUid;
            workItemContainer.surveyObjectKey = workItemContainer.workItemPreview.SurveyObjectKey;
            workItemContainer.detailsLoaded = false;
            workItemContainers.push(workItemContainer);
          });

          workItemContainers.sort((a, b) => b.workItemPreview.CreationDate.valueOf() - a.workItemPreview.CreationDate.valueOf());
          dispatch({
            workItemContainers: workItemContainers,
            previewsLoaded: true,
            previewsInProgress: false,
            previewsError: false,
            previewsErrorMessage: ''
          });
        }
      })
      .catch((e) => {
        dispatch({
          previewsInProgress: false,
          previewsError: true,
          previewsErrorMessage: e
        });
      })
  }

  const loadWorkItemDetails = (activityUid: string, surveyObjectKey: string) => {
    dispatch({ detailsInProgress: true });
    client.post(process.env.REACT_APP_BASE_API + `webservice/custom/uixservice.asmx/GetWorkItem`, { activityUid: activityUid, surveyObjectKey: surveyObjectKey })
      .then(response => {
        if (response && response.data && response.data.d) {
          let workItem = response.data.d as WorkItemDetails
          if (state.workItemContainers) {
            let workItemContainer = state.workItemContainers.filter((wc: WorkItemContainer) => {
              return wc.activityUid == activityUid && wc.surveyObjectKey == surveyObjectKey;
            });
            if (workItemContainer && workItemContainer.length == 1) {
              workItemContainer[0].detailsLoaded = true;
              workItemContainer[0].workItemDetails = resolveApprovalDetailsValues(workItem);
              dispatch({ 
                workItemContainers: state.workItemContainers,
                detailsInProgress: false,
                detailsError: false,
                detailsErrorMessage: ''
              });
            }
          }
        }
      })
      .catch((e) => {
        dispatch({
          detailsInProgress: false,
          detailsError: true,
          detailsErrorMessage: e
        });
      })
  }

  const routeWorkItem = (activityUid: string, surveyObjectKey: string, transitionUid: string) => {
    let workItemContainers = state.workItemContainers as WorkItemContainer[]
    let workItemContainer = workItemContainers.filter(wi => wi.activityUid == activityUid && (wi.surveyObjectKey ?? '') == (surveyObjectKey ?? ''));

    if (workItemContainer.length == 0) {
      return new Promise(function (resolve, reject) {
        reject("No workitemcontainer found");
      });
    }
    let workItemDetails = workItemContainer[0].workItemDetails;
    let inputValues = [];
    for (let i = 0; i < workItemDetails.FieldRegions.length; i++) {
      let fieldRegion = workItemDetails.FieldRegions[i];
      for (let j = 0; j < fieldRegion.InputFields.length; j++) {
        let valueField = fieldRegion.InputFields[j];
        if (valueField.InputValue) { inputValues.push(valueField.InputValue); }
      }
    }
    workItemDetails.routingInProgress = true;
    dispatch({ workItemContainers: state.workItemContainers });
    
    return client.post(process.env.REACT_APP_BASE_API + `webservice/custom/uixservice.asmx/RouteWorkItem`, { transitionUid: transitionUid, activityUid: activityUid, surveyObjectKey: surveyObjectKey, inputValues: inputValues }).then((res) => {
      workItemDetails.routingInProgress = false;
      workItemDetails.routingSucceeded = true;
      addNotification({ message: t('notifications.workItemRouted'), subtitle: workItemDetails.Title, category: NotificationCategory.Success, createTime: new Date(), expiresInSeconds: 60 }, NotificationType.Both);
      dispatch({ workItemContainers: state.workItemContainers })
    }).catch(err => {
      workItemDetails.routingInProgress = false;
      workItemDetails.routingFailed = true;
      workItemDetails.routingError = err;
      dispatch({ workItemContainers: state.workItemContainers })
      addNotification({ message: t('notifications.workItemRoutingFailed'), subtitle: workItemDetails.Title, category: NotificationCategory.Error, createTime: new Date(), expiresInSeconds: 60 }, NotificationType.Both);
    });
  };
  useInterval(() => {
    loadWorkItemPreviews(false);
  }, 60000);

  useEffect(() => {
    if (userContext.user.loaded) {
      loadWorkItemPreviews(true);
    }
  }, [userContext.user.loaded]);

  const value: WorkItemContextValueType = {
    workItemsState: state,
    loadWorkItemPreviews: loadWorkItemPreviews,
    loadWorkItemDetails: loadWorkItemDetails,
    routeWorkItem: routeWorkItem
  }
  return <WorkItemContext.Provider value={value}>{children}</WorkItemContext.Provider>
};

const useWorkItems = () => {
  const context = useContext(WorkItemContext)
  if (context === undefined) {
    throw new Error("useWorkItems must be used within WorkItemContext");
  }
  return context;
}
export default useWorkItems;