import { createContext, useContext, useReducer, useEffect } from "react";
import { ChildrenType } from "src/models/view/Children";
import { HelpStateType } from "src/models/view/HelpStateType";
import { client } from "src/api/client";
import { HelpContextValueType } from "src/models/view/HelpContextValueType";

export const HelpContext = createContext<HelpContextValueType>(
  {} as HelpContextValueType
);

export const HelpProvider = ({ children }: ChildrenType) => {
  const [state, dispatch] = useReducer(
    (state: HelpStateType, action: any) => ({
      ...state,
      ...action
    }), {
      helpPageContent: '',
      helpPageContentLoading: false,
      error: false,
      errorMessage: ''
    } as HelpStateType);

  const loadHelpPageContent = () => {
    dispatch({ helpPageContentLoading: true })
    client.get(process.env.REACT_APP_BASE_API + 'webservice/custom/uixservice.asmx/GetHelpPageContent')
      .then(response => {
        if (response
          && response.data
          && response.data.d) {
          dispatch({
            helpPageContent: response.data.d,
            helpPageContentLoading: false,
            error: false,
            errorMessage: ''
          });
        }
      })
      .catch((e) => {
        dispatch({
          helpPageContentLoading: false,
          error: true,
          errorMessage: e
        });
      })
  }
  useEffect(() => {

  }, []);

  const value: HelpContextValueType = {
    help: state as HelpStateType,
    loadHelpPageContent: loadHelpPageContent,
  }
  return <HelpContext.Provider value={value}>{children}</HelpContext.Provider>
};

const useHelp = () => {
  const context = useContext(HelpContext)
  if (context === undefined) {
    throw new Error("useHelp must be used within HelpContext");
  }
  return context;
}
export default useHelp;