export enum DisplayValueType {
    Simple,
    Resolver
}

export interface DisplayValue {
    Content: string;
    Type: DisplayValueType;
    TypeName: string;
    Resolver: string;
}

export interface WorkItemBase {
    ActivityUid: string;
    SurveyObjectKey: string;
    Requestor: string;
    CreationDate: Date;
    CreationDateJs : number;
    IsSurvey: boolean;
    CreationDateString: string;
    UserRegionalSettings: string;
}

export interface WorkItemPreview extends WorkItemBase {
    SubjectLabel: string;
    ContentLabel: string;
    Subject: DisplayValue;
    Content: DisplayValue;
    ResolvedSubject: string;
    ProcessedContent: string;
    AppId: string;
}

export interface WorkItemDetails extends WorkItemBase {
    Title: string;
    StartUpScript: string;
    FieldRegions: FieldRegion[];
    AttachmentRegions: AttachmentRegion[];
    SortedRegions : OrderdRegion[];
    routingInProgress:boolean;
    routingError:string;
    routingSucceeded:boolean;
    routingFailed:boolean;
    ActionRegion: ActionRegion;
    new(): WorkItemDetails;
}

export type OrderdRegion = {
    Order: number;
    Type: string;
    Region: AttachmentRegion|FieldRegion
}

export interface BaseRegion {
    new(): BaseRegion;
    Label: string;
    Visible: boolean;
    Folded: boolean;
    Order: number;
    Type: string,
}

export interface AttachmentRegion extends BaseRegion {
    AttachmentFields: AttachmentField[];
    new(): AttachmentRegion;
}

export interface ActionRegion {
    new(): ActionRegion;
    Actions: RoutingAction[];
}

export interface RoutingAction {
    new(): RoutingAction;
    TransitionUid: string;
    ActivityUid: string;
    SurveyObjectKey: string;
    ConfirmBefore: boolean;
    ConfirmMessage: string;
    Label: string;
    Order: number;
}

export interface FieldRegion extends BaseRegion {
    InputFields: InputField[];
    BuiltInFields: BuiltInField[];
    DisplayFields: DisplayField[];
    Fields: (InputField|BuiltInField|DisplayField) [];
    new(): FieldRegion;
}

export interface BaseField {
    new(): BaseField;
    Order: number;
    Visible: boolean;
    Label: string;
    FieldType: FieldTypes;
}

export interface InputField extends BaseField {
    new(): InputField;
    InputValue: InputValue;
}

export interface DisplayField extends BaseField {
    new(): DisplayField;
    DisplayValue: DisplayValue;
}

export interface BuiltInField extends BaseField {
    new(): BuiltInField;
    FunctionName: string;
}

export enum FieldTypes {
    Display,
    Input,
    BuiltIn,
    Attachment
}

export enum InputValueType {
    Value,
    Set,
    Ref
}

export enum ValueType {
    Text,
    Boolean,
    DateTime,
    Integer,
    Decimal
}

export interface SelectValue {
    new(): SelectValue;
    Id: number;
    Name: string;
}

export interface InputValue {
    InputValueType: InputValueType;
    MultipleSelection: boolean;
    SelectableValues: SelectValue[];
    SelectedValues: SelectValue[];
    ValueType: ValueType | null;
    ValueTypeName: string;
    PropertySysName: string;
    Multiline: boolean;
    InputValidation: InputValidation;
    Value: string;
    ValueInteger: number | null;
    ValueBool: boolean | null;
    ValueDateTime: Date | string | null;
    ValueDecimal: number | null;
    ValueDecimalDecimalPlaces: number;
    new(): InputValue;
}

export interface InputValidation {
    new(): InputValidation;
    ValidationRegExExpression: string;
    ValidationExceptionMessage: string;
    InputMask: string;
    MinValue: string;
    MaxValue: string;
    MinValueExpression: string;
    MaxValueExpression: string;
    Required: boolean;
    RequiredExceptionMessage: string;
  }

  export interface AttachmentField extends BaseField {
    FileName: string;
    MimeType: string;
    FileVersionID: number;
    FileID: number;
    Size: string;
    new(): AttachmentField;
 }

 export interface ValidationError {
    PropertySysName: string;
    Message: string;
    new(): ValidationError;
 }