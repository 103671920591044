import React, { useContext, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import useNotifications from 'src/contexts/NotificationContext';

import { styled } from "@mui/material/styles";


const StyledSnackbar = styled(Snackbar)(
  ({ theme }) => `
        top: 100px !important;
    `
);

const NotificationList = () => {
  const { currentNotification } = useNotifications()
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (currentNotification) {
      setOpen(true);
    } else {
      setOpen(false);
    }

  }, [currentNotification]);
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <StyledSnackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {currentNotification && (
          <Alert severity={currentNotification.category} sx={{ width: '100%' }} onClose={handleClose}>
            
            <Typography variant='h6'>
            {currentNotification.message}
            </Typography>
            <Typography variant='subtitle2'>
            {currentNotification?.subtitle??''}
            </Typography>
            
            </Alert>
        )
        }

      </StyledSnackbar>
    </>
  )
}

export default NotificationList