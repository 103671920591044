import {
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme,
  Grid,
  Typography
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: inline-flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));


interface LogoProps {
  closeSidebar?: Function;
}

const Logo: FunctionComponent<LogoProps> = ({ closeSidebar }) => {
  const theme = useTheme();
  const [t] = useTranslation();

  return (
    <Box mx={3} mt={3}>
      <Grid container spacing={3} sx={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <TooltipWrapper
            title={t('sidebar.logo.tooltip')}
            id='tooltipwrapper'
            arrow
          >
            <LogoWrapper to="/overview" sx={{ ml: { xs: 1 } }}>

              <img src="/static/images/logo-2023.svg" width="50" height="50" alt={t('sidebar.logo.alttext')} />
            </LogoWrapper>
          </TooltipWrapper>
        </Grid>
        <Grid item>
          <Typography style={{ marginTop: '5px' }} variant="h2" gutterBottom>
            MVAA
          </Typography>
        </Grid>
        {closeSidebar &&
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <ClearIcon onClick={() => { closeSidebar() }} sx={{ marginBottom: '7px', fontSize: theme.typography.h2, cursor: 'pointer' }} />
          </Grid>
        }
      </Grid>
    </Box>

  );
}

export default Logo;
