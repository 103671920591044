import React, {useEffect} from 'react'

const Favicon = () => {

  useEffect(() => 
  {
    const envName = process.env.REACT_APP_ENV_NAME;
    if (envName 
        && envName !== "PROD"
        && document
        && 'getElementById' in document) {
      const iconFilenameSvg = process.env.PUBLIC_URL + '/static/images/logo-2023-' + envName.toLowerCase() + '.svg';
      const faviconNode = document.getElementById("favicon--svg");
      if (faviconNode && 'href' in faviconNode) {
        faviconNode.href = iconFilenameSvg;
      }
    }
  })

  return (
    <></>
  )
}

export default Favicon